import { useContext } from "react";
import PricingCard from "../ui/Card/PricingCard";
import { AuthContext } from "../../context/AuthContext";

const PricingPage = () => {
  const { isAuthenticated, subscriptionActive } = useContext(AuthContext);
    const pricingData = [
      {
        price: 19,
        frequency: 'One-time fee',
        features: [
          'Receive:',
          'Your unique and powerful written story',
          'Created content from your story (5x posts)',
          'Tailored content strategy based on your story',
          'Your values'
        ],
        color:'black',
        buttonText: 'GET STARTED',
        url:'/start-your-story'
      },
      {
        price: 49,
        frequency: 'Per month',
        features: [
          'Receive:',
          'Your unique and powerful written story (6x stories)',
          'Tailored content strategy based on your story',
          'Created content from your story (30x posts)',
          'Your values'
        ],
        color:'black',
      buttonText: 'GET STARTED',
      url:'/subscription',
      isAuthenticated: isAuthenticated,
      subscriptionActive: subscriptionActive

      },
      {
        title: 'TRANSFORM YOUR STORY',
        price: 250,
        frequency: 'One-time fee',
        features: [
          'The easiest way to transform your story and communicate it with your audience',
          'Turn your story into a film by:',
          'Upload/use your own videos & photos',
          'Voice/video recording of you reading your story'
        ],
        color:'black',
        buttonText: 'COMING SOON'
      },
     
      {
        price: 3500,
        frequency: 'One-time fee',
        features: [
          'Create a professional short film of your story—perfect for businesses to connect with their ideal audience.',
          'Includes:',
          'Melo team in-person consult*',
          'In depth written story',
          'Professional photo',
          '3x social media short film'
        ],
        color:'black',
        buttonText: 'GET STARTED',
        url:'/turn-your-story-into-a-film'
      },
      {
        title: 'GIFT YOUR STORY',
        price: 40,
        frequency: 'exc. shipping',
        features: [
          'Turn your story into a beautifully framed gift set. Perfect for celebrating special moments or gifting to someone special.',
          'Includes:',
          'A5 prints on 250 gsm uncoated paper (1x story, 1x edited image)',
          'Luxury bi-fold frame',
          'Gift box'
        ],
        color:'black',
        buttonText: 'Pre-order Now',
        url:'/gift-your-story'
      },
      {
        price: 19,
        frequency: 'One-time fee',
        features: [
          'Receive:',
          'Your unique and powerful written story',
          'Created content from your story (5x posts)',
          'Tailored content strategy based on your story',
          'Your values'
        ],
        color:'black',
        buttonText: 'GET STARTED',
        url:'/start-your-story'
      },
    ];
    
  
    return (
      <div 
      className="flex flex-col mt-8"
      >
        <h1 className="text-4xl text-center  font-playfair font-semibold mb-2">YOUR STORY</h1>

        <div>
          {pricingData.map((plan, index) => (
            <PricingCard key={index} {...plan} />
          ))}
        </div>
        
      </div>
    );
  };
  
  export default PricingPage;
