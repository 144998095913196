import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCache } from "../../CacheContext";
import { loadStripe } from "@stripe/stripe-js";
const Order = () => {
  const [loading, setLoading] = useState(true);

  const { setCacheData, getCacheData } = useCache();
  const [orders, setOrders] = useState(() => getCacheData("orders") || []);

  useEffect(() => {
    if (!orders.length) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/order`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log("orders api call made");
          setOrders(response.data.data);
          setCacheData("orders", response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message ||
            error.message ||
            "An unexpected error occurred while fetching the orders";
          toast.error(errorMessage);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [orders, setCacheData]);
  const handleOrderClick = async(
    url
  ) => {
    if(!url) {toast.error("No receipt found for this order");return;}
    
    
      window.open(url, '_blank');
    

  }
  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (orders.length === 0) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No orders found</p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />
      <div className="overflow-x-auto w-full">
        <table className="w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Order ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Product
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Price
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {orders.map((order) => (
              <tr key={order._id}
              onClick={() => handleOrderClick(order?.receipt_url || order?.receiptUrl
              )} className="cursor-pointer hover:bg-gray-100"
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {order._id}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {order.productName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  ${order.totalAmount.toFixed(2)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {moment().diff(moment(order.createdAt), "hours") < 24
                    ? moment(order.createdAt).fromNow()
                    : moment(order.createdAt).format("MMMM Do YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Order;
